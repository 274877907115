<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <md-card class="bg-success h-100">
          <md-card-content>
            <h5 class="card-category card-category-social text-white">
              <i class="far fa-newspaper" /> Pricing
            </h5>
            <h4 class="card-title text-white">
              Lists for your Products
            </h4>
            <p class="card-description text-white">
              Create the pricing lists you can then assign to your customers. A
              customer can have 1 price list per channel only. <br />You can
              also assign a company a price list in the CRM.
            </p>

            <div class="card-stats text-center" />
          </md-card-content>
        </md-card>
      </div>
      <div class="col-lg-8" style="display: none;" />
    </div>

    <div class="row">
      <div class="col-lg-12">
        <v-card class="mx-auto">
          <v-toolbar color="grey" dark>
            <v-toolbar-title class="display-3">
              Product Pricing
            </v-toolbar-title>

            <v-spacer />

            <v-dialog
              v-model="importdialog"
              hide-overlay
              max-width="600px"
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar dark color="primary">
                  <v-btn icon dark @click="importdialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Import Pricing</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <div class="container">
                  <div class="display-1 mb-5 mt-4">
                    Important: The file must be comma-separated only.
                  </div>
                  <v-spacer />
                  <b-form-file
                    v-model="csvFile"
                    placeholder="Select CSV file"
                    class="mb-5"
                    @change="csvChange"
                  />
                  <!-- <v-file-input
    label="Select CSV file"
    outlined
    dense
    v-model="csvFile"
  ></v-file-input>      -->

                  <!-- <v-select
                    :items="imports"
                    dense
                    label="Import Method"
                    class="mb-6"
                    ></v-select> -->
                  <div
                    v-if="errorShow"
                    class="display-1 mb-5"
                    style="color: red; margin-left: 35px;"
                  >
                    Validation: These are invalid lines
                    <div v-for="(err, index) in importError" :key="index">
                      {{ err }}
                    </div>
                  </div>
                  <div
                    v-if="validationSuccessShow"
                    class="display-1 mb-5"
                    style="color: green; margin-left: 35px;"
                  >
                    Validation success
                  </div>
                  <div
                    v-if="importSuccessShow"
                    class="display-1 mb-5"
                    style="color: green; margin-left: 35px;"
                  >
                    Import success
                  </div>
                  <v-btn elevation="2" rounded @click="downloadTemplate()">
                    Download Template
                  </v-btn>
                  <v-divider />

                  <v-btn
                    color="primary"
                    class="mr-4"
                    @click="importdialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="secondary"
                    class="mr-4"
                    @click="validatePricing"
                  >
                    validate
                  </v-btn>

                  <v-btn
                    color="success"
                    class="mr-4"
                    :disabled="importDisable"
                    @click="importPricing"
                  >
                    Import
                  </v-btn>
                </div>

                <v-overlay :opacity="0.46" :value="isLoading">
                  <v-progress-circular indeterminate size="36" />
                </v-overlay>
              </v-card>
            </v-dialog>
            <v-btn color="primary" dark @click="importDialogOpen">
              Import
            </v-btn>
            <v-btn dark class="ml-4" @click="exportPricing">
              Export
            </v-btn>
            <v-spacer />
            <v-btn elevation="2" rounded dark href="/pim/pricing/">
              Back to List
            </v-btn>
          </v-toolbar>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="listname"
                    label="Price List Name"
                    required
                  />
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <span v-if="isDefault">Default price list</span>
                  <v-checkbox
                    v-model="noExpiration"
                    label="No Expiration"
                    :disabled="isDefault"
                    :style="
                      isDefault ? 'margin-top: 0px;' : 'margin-top: 16px;'
                    "
                  />
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-menu
                    ref="expireDateMenu"
                    v-model="expireDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="expireDateFormatted"
                        label="Expiration Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :disabled="noExpiration || isDefault"
                        @blur="expireDate = parseDate(expireDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="expireDate"
                      no-title
                      @input="expireDateMenu = false"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="expireDateMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.expireDateMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer />
                <v-col cols="3">
                  <v-btn color="primary" dark @click="save">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="searchSku"
                class="ml-3"
                label="Search by SKU"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="searchItem" label="Search by Item" />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="searchProduct" label="Search by Product" />
            </v-col>
            <v-col cols="3">
              <v-btn color="primary" dark @click="addDialogOpen">
                Add Product Price
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            hide-default-footer
            disable-sort
          >
            <template #item="row">
              <tr>
                <td>{{ row.item.sku_id }}</td>
                <td>
                  <span v-if="row.item.Name != 'default'">{{
                    row.item.Name
                  }}</span>
                  <span v-else-if="row.item.product != null">{{
                    row.item.product.name
                  }}</span>
                </td>
                <td>
                  <span v-if="row.item.product != null">{{
                    row.item.product.name
                  }}</span>
                </td>
                <td>
                  <span v-if="qty1list[row.item.id] != undefined">{{
                    qty1list[row.item.id] | dollar_format
                  }}</span>
                </td>
                <td>
                  <v-btn
                    text
                    color="primaryt"
                    @click="editItem(row.item.id, row.item.sku_id)"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Edit Tiers
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>

          <div class="row mt-6">
            <b-pagination
              v-model="itemCurrentPage"
              :total-rows="itemListCount"
              :per-page="limit"
              style="margin: 0 auto;"
            />
          </div>
        </v-card>
      </div>
    </div>

    <v-dialog
      v-model="itemdialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="itemdialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Item Price Tiers </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items />
        </v-toolbar>
        <div class="container">
          <div
            class="display-1 mb-2 mt-3"
            :style="'color: ' + itemDialogInfoColor + ';'"
          >
            <span>{{ itemDialogInfo }}</span>
          </div>
          <div class="display-1 mb-2 mt-3">
            Tier Prices for Item {{ active_sku_id }}
          </div>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in itemData" :key="index">
                      <td>
                        {{ item.qty }}
                      </td>
                      <td>
                        <v-text-field
                          :ref="'inputprice_' + item.id"
                          v-model="item.price"
                          class="mt-4"
                          filled
                          prepend-inner-icon="mdi-currency-usd"
                          label="Price"
                          style="width: 150px"
                          @keypress="vueValidateFloatKeyPress"
                        />
                      </td>
                      <td>
                        <!-- <v-btn
                          small
                          class="mr-2"
                          @click="editPriceItem(item.id)"
                          :ref="'editpriceitem_'+item.id"
                        >
                          Edit
                        </v-btn> -->
                        <v-btn
                          :ref="'savepriceitem_' + item.id"
                          small
                          color="primary"
                          class="mr-2 ml-2"
                          @click="savePriceItem(item)"
                        >
                          Update
                        </v-btn>
                        <v-btn
                          v-if="item.qty != '1'"
                          small
                          class="ml-2"
                          @click="deletePriceItem(item.id)"
                        >
                          Remove
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider />

              <div class="display-1 mb-2 mt-3">
                Add a Price Tier for this Item
              </div>
              <v-form v-model="addItemformvalid" @submit.prevent="addItem">
                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      v-model="addQty"
                      type="number"
                      filled
                      label="QTY"
                      :rules="[rules.qty]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <!-- <input v-model="addPrice" @keypress="onlyForCurrency"/> -->
                    <v-text-field
                      v-model="addPrice"
                      filled
                      prepend-inner-icon="mdi-currency-usd"
                      label="Price"
                      @keypress="vueValidateFloatKeyPress"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="primary"
                      class="mr-4"
                      type="submit"
                      :disabled="!addItemformvalid"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-row class="mt-6">
            <v-col cols="12">
              <v-btn color="primary" class="mr-4" @click="itemdialog = false">
                Close
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addDialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Product Price</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items />
        </v-toolbar>
        <div class="container">
          <div
            class="display-1 mb-3 mt-3"
            style="color: red;"
            v-text="addDialogInfo"
          />
          <v-form v-model="addDialogformvalid" @submit.prevent="addManualItem">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="manualItem"
                  label="Item"
                  :items="manualItems"
                  item-text="Name"
                  item-value="id"
                >
                  <template slot="item" slot-scope="{ item }">
                    <p>{{ item.Name }} ({{ item.sku_id }})</p>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model="manualQty"
                  label="QTY"
                  :rules="[rules.qty]"
                />
                <v-text-field
                  v-model="manualPrice"
                  prepend-inner-icon="mdi-currency-usd"
                  label="Price"
                  @keypress="vueValidateFloatKeyPress"
                />
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="mr-4"
                  type="submit"
                  :disabled="!addDialogformvalid"
                >
                  Add
                </v-btn>

                <v-btn class="mr-4" @click="addDialog = false">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import { validationMixin } from 'vuelidate'

import { mapActions } from 'vuex'
import PricingItems from '@/graphql/queries/PricingItems.gql'
import PricingItemsData from '@/graphql/queries/PricingItemsData.gql'

export default {
  filters: {
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      items: [],
      isDefault: false,
      addDialogformvalid: false,
      addItemformvalid: false,
      rules: {
        qty: (v) => /^\d+$/.test(v) || 'Qty must be valid',
        price: (v) => /^\d+(?:\.\d{0,2})?$/.test(v) || 'Price must be valid',
      },
      searchSku: '',
      searchItem: '',
      searchProduct: '',
      itemDialogInfo: '',
      itemDialogInfoColor: 'red',
      addDialogInfo: '',
      manualItems: [],
      manualItem: '',
      manualQty: '',
      manualPrice: '',
      addDialog: false,
      isLoading: false,
      validationSuccessShow: false,
      importSuccessShow: false,
      importDisable: true,
      qty1list: {},
      errorShow: false,
      importError: [],
      csvFile: null,
      addQty: '',
      addPrice: '',
      active_item_id: '',
      active_sku_id: '',
      importdialog: false,
      itemdialog: false,
      limit: 10,
      itemCurrentPage: 1,
      itemListCount: 0,
      headers: [
        {
          text: 'SKU ID',
          align: 'start',
        },
        {
          text: 'Item Name',
        },
        {
          text: 'Parent Product',
        },
        {
          text: 'Qty 1 Price',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      expireDate: new Date().toISOString().substr(0, 10),
      expireDateFormatted: this.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      expireDateMenu: false,
      listname: '',
      noExpiration: false,
      imports: ['Add and Replace', 'Reset and Replace'],
      channels: ['Premium', 'Discount', 'Trial'],
    }
  },
  apollo: {
    items: {
      query: PricingItems,
      variables() {
        return {
          searchItem: this.searchItem === '' ? undefined : this.searchItem,
          searchSku: this.searchSku === '' ? undefined : this.searchSku,
          searchProduct:
            this.searchProduct === '' ? undefined : this.searchProduct,
          supplier_company: this.$store.state.auth.user.company_relation,
          limit: this.limit,
          start: (this.itemCurrentPage - 1) * this.limit,
        }
      },
    },
    itemData: {
      query: PricingItemsData,
      variables() {
        return {
          item_id: this.active_item_id,
          pricelist_id: parseInt(this.$route.params.id),
        }
      },
      skip() {
        return this.active_item_id == ''
      },
    },
  },
  watch: {
    expireDate() {
      this.expireDateFormatted = this.formatDate(this.expireDate)
    },
  },
  created() {
    this.getPricelistById({
      id: this.$route.params.id,
    }).then((res) => {
      if (res.data != false) {
        this.listname = res.data.listname
        this.expireDate = res.data.expiration
        this.noExpiration = res.data.noexpiration
      } else {
        Swal.fire({
          title: '',
          text: 'Invalid price list.',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
        this.$router.push('/pim/pricing')
      }
    })

    this.refreshPricelistItem()

    setTimeout(() => {
      this.getCompanyInfo({
        id: this.$store.state.auth.user.company_relation,
      }).then((cres) => {
        if (cres.data.default_pricelist != null) {
          if (cres.data.default_pricelist.id == this.$route.params.id) {
            this.isDefault = true
          }
        }
      })

      this.getItemWhere({
        where:
          '?_where[0][product.supplier]=' +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        if (res.data != false) {
          this.itemListCount = res.data.length
          this.manualItems = res.data
        }
      })
    }, 1000)
  },

  methods: {
    ...mapActions([
      'submitPricelist',
      'removePricelist',
      'getPricelistById',
      'updatePricelist',
      'getPricelistWhere',
      'getItemTotalCount',
      'submitPricelistItem',
      'getItemWhere',
      'updatePricelistItem',
      'removePricelistItem',
      'getPricelistItemTotalCount',
      'getPricelistItemWhere',
      'getItemById',
      'getCompanyInfo',
    ]),
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}-${day}-${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    vueValidateFloatKeyPress($event) {
      var el = $event.path[0]
      var evt = $event
      var charCode = evt.which ? evt.which : event.keyCode
      var number = el.value.split('.')
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        $event.preventDefault()
      }
      //just one dot
      if (number.length > 1 && charCode == 46) {
        $event.preventDefault()
      }
      //get the carat position
      var caratPos = this.getSelectionStart(el)
      var dotPos = el.value.indexOf('.')
      if (caratPos > dotPos && dotPos > -1 && number[1].length > 1) {
        $event.preventDefault()
      }
    },
    getSelectionStart(o) {
      if (o.createTextRange) {
        var r = document.selection.createRange().duplicate()
        r.moveEnd('character', o.value.length)
        if (r.text == '') return o.value.length
        return o.value.lastIndexOf(r.text)
      } else return o.selectionStart
    },
    async addManualItem() {
      if (
        this.manualItem != '' &&
        this.manualQty != '' &&
        this.manualPrice != '' &&
        !isNaN(+this.manualQty) &&
        !isNaN(+this.manualPrice)
      ) {
        let skuid_items = await this.getItemById({
          id: this.manualItem,
        })
        if (skuid_items.data != false) {
          let plres = await this.getPricelistItemWhere({
            where:
              '?_where[0][item]=' +
              this.manualItem +
              '&_where[1][pricelist]=' +
              this.$route.params.id +
              '&_sort=qty:asc',
          })
          if (plres.data.length > 0) {
            let flag = 0
            plres.data.map((pl) => {
              if (
                parseFloat(this.manualPrice) >= parseFloat(pl.price) &&
                parseInt(pl.qty) <= parseInt(this.manualQty)
              ) {
                flag = 1
              }
              if (
                parseFloat(this.manualPrice) <= parseFloat(pl.price) &&
                parseInt(pl.qty) >= parseInt(this.manualQty)
              ) {
                flag = 2
              }
            })
            if (flag == 1) {
              this.addDialogInfo =
                'The price is higher than other prices with lower qty!'
              return
            }
            if (flag == 2) {
              this.addDialogInfo = 'Error in price!'
              return
            }
          }

          let pres = await this.getPricelistItemWhere({
            where:
              '?_where[0][item]=' +
              this.manualItem +
              '&_where[1][pricelist]=' +
              this.$route.params.id +
              '&_where[2][qty]=' +
              this.manualQty,
          })

          if (pres.data.length > 0) {
            this.updatePricelistItem({
              id: pres.data[0].id,
              qty: this.manualQty,
              price: this.manualPrice,
            }).then(() => {
              this.refreshPricelistItem()
              this.addDialog = false
              Swal.fire({
                title: '',
                text: 'Updated successfully!',
                icon: 'success',
                confirmButtonClass: 'btn btn-secondary',
              })
            })
          } else {
            let res = await this.submitPricelistItem({
              item: parseInt(this.manualItem),
              productname: skuid_items.data.Name,
              productsku: skuid_items.data.sku_id,
              qty: this.manualQty,
              price: this.manualPrice,
              pricelist: parseInt(this.$route.params.id),
            })
            if (res.data != false) {
              this.manualQty = ''
              this.manualPrice = ''
              this.refreshPricelistItem()
              this.addDialog = false
              Swal.fire({
                title: '',
                text: 'Added successfully!',
                icon: 'success',
                confirmButtonClass: 'btn btn-secondary',
              })
            }
          }
        }
      } else {
        if (this.manualQty != '' || isNaN(+this.manualQty)) {
          this.addDialogInfo = 'Please type correct Qty!'
        } else if (this.manualPrice != '' || isNaN(+this.manualPrice)) {
          this.addDialogInfo = 'Please type correct Price!'
        } else {
          this.addDialogInfo = 'Invalid Information!'
        }
      }
    },
    addDialogOpen() {
      this.addDialogInfo = ''
      this.manualItem = ''
      this.manualQty = ''
      this.manualPrice = ''
      this.addDialog = true
    },
    csvChange() {
      this.errorShow = false
      this.validationSuccessShow = false
      this.importSuccessShow = false
      this.importDisable = true
    },
    refreshPricelistItem() {
      this.getPricelistItemWhere({
        where:
          '?_where[0][pricelist]=' +
          this.$route.params.id +
          '&_where[1][qty]=1',
      }).then((pres) => {
        for (let i in pres.data) {
          this.qty1list[pres.data[i].item.id] = pres.data[i].price
        }
        this.items = []
        setTimeout(() => {
          this.$apollo.queries.items.refetch()
        }, 500)
      })
    },
    exportPricing() {
      this.getItemWhere({
        where:
          '?_where[0][product.supplier]=' +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        let sku_arr = []
        for (let i in res.data) {
          sku_arr.push(res.data[i].sku_id)
        }
        this.getPricelistItemWhere({
          where: '?_where[0][pricelist]=' + this.$route.params.id,
        }).then((pres) => {
          let csv = 'Product SKU,Qty,Price\n'
          for (let i in pres.data) {
            let index = sku_arr.indexOf(pres.data[i].item.sku_id)
            if (index > -1) {
              sku_arr.splice(index, 1)
            }
            csv +=
              pres.data[i].item.sku_id +
              ',' +
              pres.data[i].qty +
              ',' +
              pres.data[i].price +
              '\n'
          }
          for (let i in sku_arr) {
            csv += sku_arr[i] + ',1,0\n'
          }
          const anchor = document.createElement('a')
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
          anchor.target = '_blank'
          anchor.download = 'export_pricing.csv'
          anchor.click()
        })
      })
    },
    importDialogOpen() {
      this.errorShow = false
      this.validationSuccessShow = false
      this.importSuccessShow = false
      this.csvFile = null
      this.importDisable = true
      this.importdialog = true
    },
    async validatePricing() {
      if (this.csvFile) {
        var reader = new FileReader()

        reader.readAsText(this.csvFile)
        var self = this
        reader.onload = async function() {
          self.isLoading = true
          let lines = reader.result.split('\n')
          self.importError = []
          for (var i = 1; i < lines.length; i++) {
            var currentLine = lines[i].split(',')
            if (currentLine != '') {
              var flag = 0
              if (currentLine.length > 1) {
                var sku_id = currentLine[0]
                var qty = currentLine[1]
                var price = currentLine[2]

                if (
                  sku_id != '' &&
                  qty != '' &&
                  price != '' &&
                  !isNaN(+qty) &&
                  self.isInt(parseFloat(qty)) &&
                  !isNaN(+price)
                ) {
                  let ires = await self.getItemWhere({
                    where:
                      '?_where[0][sku_id]=' +
                      sku_id +
                      '&_where[1][product.supplier]=' +
                      self.$store.state.auth.user.company_relation,
                  })
                  if (ires.data.length > 0) {
                    flag = 1
                  }
                }
              }
              if (flag == 0) {
                self.importError.push(lines[i])
              }
            }
          }

          if (self.importError.length > 0) {
            self.errorShow = true
          } else {
            self.errorShow = false
            self.importDisable = false
            self.validationSuccessShow = true
          }
          self.isLoading = false
        }
      }
    },
    async importPricing() {
      if (this.csvFile) {
        var reader = new FileReader()
        reader.readAsText(this.csvFile)
        var self = this
        reader.onload = async function() {
          self.isLoading = true
          let lines = reader.result.split('\n')
          for (var i = 1; i < lines.length; i++) {
            var currentLine = lines[i].split(',')

            if (currentLine != '') {
              if (currentLine.length > 1) {
                var sku_id = currentLine[0]
                var qty = currentLine[1]
                var price = currentLine[2]

                if (
                  sku_id != '' &&
                  qty != '' &&
                  price != '' &&
                  !isNaN(+qty) &&
                  self.isInt(parseFloat(qty)) &&
                  !isNaN(+price)
                ) {
                  let ires = await self.getItemWhere({
                    where:
                      '?_where[0][sku_id]=' +
                      sku_id +
                      '&_where[1][product.supplier]=' +
                      self.$store.state.auth.user.company_relation,
                  })
                  if (ires.data.length > 0) {
                    let pres = await self.getPricelistItemWhere({
                      where:
                        '?_where[0][item]=' +
                        ires.data[0].id +
                        '&_where[1][pricelist]=' +
                        self.$route.params.id +
                        '&_where[2][qty]=' +
                        qty,
                    })
                    if (pres.data.length > 0) {
                      await self.updatePricelistItem({
                        id: pres.data[0].id,
                        qty: qty,
                        price: price,
                      })
                    } else {
                      await self.submitPricelistItem({
                        item: parseInt(ires.data[0].id),
                        productname: ires.data[0].Name,
                        productsku: sku_id,
                        qty: qty,
                        price: price,
                        pricelist: parseInt(self.$route.params.id),
                      })
                    }
                  }
                }
              }
            }
          }
          self.refreshPricelistItem()
          self.isLoading = false
          self.importdialog = false
          Swal.fire({
            title: '',
            text: 'Import success.',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })
        }
      }
    },
    downloadTemplate() {
      let csv = 'Product SKU,Qty,Price\n'
      csv += 'sku001,1,10.89\n'

      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = 'pricing_template.csv'
      anchor.click()
    },
    editPriceItem(id) {
      if (
        this.$refs['inputprice_' + id] != null &&
        this.$refs['spanprice_' + id] != null
      ) {
        this.$refs['spanprice_' + id][0].style = []
        this.$refs['inputprice_' + id][0].style.display = 'block'
        this.$refs['spanprice_' + id][0].style.display = 'none'
      }
      if (
        this.$refs['editpriceitem_' + id] != null &&
        this.$refs['savepriceitem_' + id] != null
      ) {
        this.$refs['editpriceitem_' + id][0].$el.style = []
        this.$refs['savepriceitem_' + id][0].$el.style.display = 'unset'
        this.$refs['editpriceitem_' + id][0].$el.style.display = 'none'
      }
    },
    async savePriceItem(item) {
      if (item.qty != '' && item.price != '' && !isNaN(+item.price)) {
        // check price is lower than above
        let plres = await this.getPricelistItemWhere({
          where:
            '?_where[0][item]=' +
            item.item.id +
            '&_where[1][pricelist]=' +
            this.$route.params.id +
            '&_sort=qty:asc',
        })
        if (plres.data.length > 0) {
          let flag = 0
          for (let i in plres.data) {
            if (
              parseInt(plres.data[i].qty) < parseInt(item.qty) &&
              parseFloat(item.price) > parseFloat(plres.data[i].price)
            ) {
              flag = 1
            }
          }
          if (flag == 1) {
            this.itemDialogInfo =
              'The price is higher than other prices with lower qty!'
            setTimeout(() => {
              this.itemDialogInfo = ''
              this.itemDialogInfoColor = 'red'
            }, 3000)
            return
          }
        }

        await this.updatePricelistItem({
          id: item.id,
          qty: item.qty,
          price: item.price,
        })
        // if(this.$refs['inputprice_'+id] != null && this.$refs['spanprice_'+id] != null) {
        //   this.$refs['inputprice_'+id][0].style.display = 'none';
        //   this.$refs['spanprice_'+id][0].style.display = 'block';
        // }
        // if(this.$refs['editpriceitem_'+id] != null && this.$refs['savepriceitem_'+id] != null) {
        //   this.$refs['editpriceitem_'+id][0].$el.style.display = 'unset';
        // }
        this.itemDialogInfo = 'Successfully updated!'
        this.itemDialogInfoColor = 'green'
        setTimeout(() => {
          this.itemDialogInfo = ''
          this.itemDialogInfoColor = 'red'
        }, 3000)
        this.$apollo.queries.itemData.refetch()
        this.refreshPricelistItem()
      } else {
        if (item.price == '' || isNaN(+item.price)) {
          this.itemDialogInfo = 'Please type correct price!'
          this.itemDialogInfoColor = 'red'
        } else {
          this.itemDialogInfo = 'Invalid Information!'
          this.itemDialogInfoColor = 'red'
        }
        setTimeout(() => {
          this.itemDialogInfo = ''
          this.itemDialogInfoColor = 'red'
        }, 3000)
      }
    },
    deletePriceItem(id) {
      this.removePricelistItem({
        id: id,
      }).then(() => {
        this.$apollo.queries.itemData.refetch()
        this.refreshPricelistItem()
      })
    },
    isInt(n) {
      return n % 1 === 0
    },
    async addItem() {
      if (
        this.active_item_id != '' &&
        parseInt(this.addQty) != 0 &&
        !isNaN(+this.addQty) &&
        this.isInt(parseFloat(this.addQty)) &&
        !isNaN(+this.addPrice)
      ) {
        let skuid_items = await this.getItemWhere({
          where:
            '?_where[0][id]=' +
            this.active_item_id +
            '&_where[1][product.supplier]=' +
            this.$store.state.auth.user.company_relation,
        })
        if (skuid_items.data.length > 0) {
          let plres = await this.getPricelistItemWhere({
            where:
              '?_where[0][item]=' +
              this.active_item_id +
              '&_where[1][pricelist]=' +
              this.$route.params.id +
              '&_sort=qty:asc',
          })
          if (plres.data.length > 0) {
            let flag = 0
            plres.data.map((pl) => {
              if (
                parseFloat(this.addPrice) >= parseFloat(pl.price) &&
                parseInt(pl.qty) <= parseInt(this.addQty)
              ) {
                flag = 1
              }
              if (
                parseFloat(this.addPrice) <= parseFloat(pl.price) &&
                parseInt(pl.qty) >= parseInt(this.addQty)
              ) {
                flag = 2
              }
            })
            if (flag == 1) {
              this.itemDialogInfo =
                'The price is higher than other prices with lower qty!'
              return
            }
            if (flag == 2) {
              this.itemDialogInfo = 'Error in price!'
              return
            }
          }

          let pres = await this.getPricelistItemWhere({
            where:
              '?_where[0][item]=' +
              this.active_item_id +
              '&_where[1][pricelist]=' +
              this.$route.params.id +
              '&_where[2][qty]=' +
              parseInt(this.addQty),
          })

          if (pres.data.length > 0) {
            this.updatePricelistItem({
              id: pres.data[0].id,
              qty: parseInt(this.addQty),
              price: this.addPrice,
            }).then(() => {
              this.$apollo.queries.itemData.refetch()
              this.refreshPricelistItem()
            })
          } else {
            let res = await this.submitPricelistItem({
              item: parseInt(skuid_items.data[0].id),
              productname: skuid_items.data[0].Name,
              productsku: skuid_items.data[0].sku_id,
              qty: parseInt(this.addQty),
              price: this.addPrice,
              pricelist: parseInt(this.$route.params.id),
            })
            if (res.data != false) {
              this.itemDialogInfo = ''
              this.addQty = 0
              this.addPrice = 0
              this.$apollo.queries.itemData.refetch()
              this.refreshPricelistItem()
            }
          }
        }
      } else {
        if (
          parseInt(this.addQty) != 0 ||
          this.addQty == '' ||
          isNaN(+this.addQty) ||
          !this.isInt(parseFloat(this.addQty))
        ) {
          this.itemDialogInfo = 'Please input correct QTY!'
        } else if (this.addPrice == '' || isNaN(+this.addPrice)) {
          this.itemDialogInfo = 'Please input correct Price!'
        } else {
          this.itemDialogInfo = 'Invalid information!'
        }
      }
    },
    editItem(id, sku_id) {
      this.active_item_id = id
      this.active_sku_id = sku_id
      this.addQty = 0
      this.addPrice = 0
      this.itemDialogInfo = ''
      this.$apollo.queries.itemData.refetch()
      this.itemdialog = true
    },
    save() {
      if (this.listname != '') {
        this.getPricelistWhere({
          where: '?_where[0][listname]=' + this.listname,
        }).then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].id != this.$route.params.id) {
              Swal.fire({
                title: '',
                text: 'Duplicate price list name.',
                icon: 'error',
                confirmButtonClass: 'btn btn-secondary',
              })
              return
            }
          }

          this.updatePricelist({
            listname: this.listname,
            expiration: this.expireDate,
            noexpiration: this.noExpiration,
            id: this.$route.params.id,
          }).then(() => {
            Swal.fire({
              title: '',
              text: 'Updated successfully.',
              icon: 'success',
              confirmButtonClass: 'btn btn-secondary',
            })
            return
          })
        })
      } else {
        Swal.fire({
          title: '',
          text: 'Please type price list name.',
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary',
        })
        return
      }
    },
  },
}
</script>
<style></style>
